import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  DeletedFeedPost,
  joinFeedPost,
  leaveFeedPost,
  onFeedPostDeleted,
  onFeedPostReview,
  onFeedPostUpdated,
  onNewFeedPost,
  unsubscribeFeedPostDeleted,
  unsubscribeFeedPostReview,
  unsubscribeFeedPostUpdated,
  unsubscribeNewFeedPost,
} from 'src/apis/socket.io/feed-post.socket.io';
import { Post } from 'src/models/Post.model';
import { useFeedPostsQueryClient } from './useFeedPosts';

export const useNewAndExistingFeedPost = () => {
  const { id: feedId }: { id: string } = useParams();
  const feedIdInt = +feedId;
  const { addFeedPost, updateFeedPost, deleteFeedPost } =
    useFeedPostsQueryClient({
      feedId: feedIdInt,
    });

  const handleNewFeedPost = (newPost: Post) => {
    if (newPost.FeedId === feedIdInt) {
      addFeedPost(newPost);
    }
  };

  const handleUpdatedFeedPost = (updatedPost: Post) => {
    if (updatedPost.FeedId === feedIdInt) {
      updateFeedPost(updatedPost);
    }
  };

  const handleDeletedFeedPost = (deletedPost: DeletedFeedPost) => {
    if (deletedPost.feedId === feedIdInt) {
      deleteFeedPost({ postId: deletedPost.postId });
    }
  };

  useEffect(() => {
    onNewFeedPost(handleNewFeedPost);
    onFeedPostUpdated(handleUpdatedFeedPost);
    onFeedPostDeleted(handleDeletedFeedPost);
    onFeedPostReview(handleNewFeedPost);

    return () => {
      unsubscribeNewFeedPost(handleNewFeedPost);
      unsubscribeFeedPostUpdated(handleUpdatedFeedPost);
      unsubscribeFeedPostDeleted(handleDeletedFeedPost);
      unsubscribeFeedPostReview(handleNewFeedPost);
    };
  }, []);

  useEffect(() => {
    if (!feedId) return;

    joinFeedPost(feedIdInt);

    return () => {
      leaveFeedPost(feedIdInt);
    };
  }, [feedIdInt]);
};
