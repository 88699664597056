import { socket } from 'src/config/socketIoInstance';
import { Comment } from 'src/models/Comment.model';
import { ValueCallback } from 'src/types/common.type';
import { kCommentEvents } from './events/comment.events';

export type DeletedComment = {
  commentId: number;
  entityId: number;
};

export const onNewComment = (callback: ValueCallback<Comment>) =>
  socket.on(kCommentEvents.commentCreated, callback);

export const unsubscribeNewComment = (callback: ValueCallback<Comment>) => {
  socket.off(kCommentEvents.commentCreated, callback);
};

export const onCommentUpdated = (callback: ValueCallback<Comment>) =>
  socket.on(kCommentEvents.commentUpdated, callback);

export const unsubscribeCommentUpdated = (callback: ValueCallback<Comment>) => {
  socket.off(kCommentEvents.commentUpdated, callback);
};

export const onCommentDeleted = (callback: ValueCallback<DeletedComment>) =>
  socket.on(kCommentEvents.commentDeleted, callback);

export const unsubscribeCommentDeleted = (
  callback: ValueCallback<DeletedComment>,
) => {
  socket.off(kCommentEvents.commentDeleted, callback);
};

export const joinComment = (entityId: number) => {
  socket.emit(kCommentEvents.joinComment, { entityId });
};

export const leaveComment = (entityId: number) => {
  socket.emit(kCommentEvents.leaveComment, { entityId });
};
