import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { TranslationKeys } from 'src/constants/translation-keys';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useTranslate } from 'src/hooks/useTranslate';
import CurrentUserAvatar from '../../../components/CurrentUserAvatar';
import { FeedModel } from '../../../models';
import { FeedMember } from '../../../models/FeedMember.model';

interface AddPostViewProps {
  handleOpen: () => void;
  feed?: FeedModel;
  currentUserMemberRecord?: FeedMember;
}

export const AddPostView: FC<AddPostViewProps> = ({
  feed,
  currentUserMemberRecord,
  handleOpen,
}) => {
  const { translate } = useTranslate();
  const { currentUser } = useUserDetails();
  if (!currentUser) {
    return null;
  }

  if (!feed?.allowsPostByAnyUser && !currentUserMemberRecord?.canPost) {
    return null;
  }

  return (
    <>
      <Flex
        alignItems='center'
        w='full'
        px='2'
        py='4'
        borderBottom='3px solid'
        borderColor='gray.100'
        bg='gray.100'
      >
        <CurrentUserAvatar />
        <Box
          cursor='pointer'
          border='1px solid'
          borderColor='gray.300'
          w='full'
          mx='2'
          borderRadius='md'
          p='3'
          bg='white'
          onClick={handleOpen}
        >
          <Text color='gray.500' fontWeight='semibold' letterSpacing='wide'>
            {translate(TranslationKeys.writeSomethingHere)}
          </Text>
        </Box>
      </Flex>
      <Box minW='full' minH='0.5' bg='gray.300' mb='2' />
    </>
  );
};
