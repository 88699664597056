import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import { TranslationKeys } from 'src/constants/translation-keys';
import { useTranslate } from 'src/hooks/useTranslate';

const HomePageHeading: React.FC = () => {
  const { translate } = useTranslate();

  return (
    <Box paddingTop={{ base: '3', sm: '4' }}>
      <Text
        textAlign='center'
        fontWeight='bold'
        fontSize={{ base: 'xl', sm: '3xl' }}
        noOfLines={1}
      >
        {translate(TranslationKeys.applicationHeading)}
      </Text>
    </Box>
  );
};

export default HomePageHeading;
