import { useState } from 'react';
import { useMutation, useQuery, UseQueryResult } from 'react-query';

import { markAllMessagesSeenForOneThread } from 'src/apis/message-threads.api';
import {
  getAlertChatMessages,
  getASRChatMessages,
  getMessages,
} from 'src/apis/messages.api';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { REFETCH_CONFIG } from 'src/constants';
import { Message, MessageType } from 'src/models/Message.model';

import getAllMessagesQueryKey from './getMessagesQueryKey';
import useMessageStatusUpdateObserver from './useMessageStatusUpdateObserver';
import useNewMessageObserver from './useNewMessageObserver';

interface UseMessagesOptions {
  messageThreadId: number;
  messageType: MessageType;
  isLiveChat?: boolean;
  refetchMembersLocation?: UseQueryResult['refetch'];
}

const useMessages = ({
  messageThreadId,
  messageType,
  isLiveChat = false,
  refetchMembersLocation,
}: UseMessagesOptions) => {
  const { currentUser } = useUserDetails();
  const [haveMarkedSeen, setHaveMarkedSeen] = useState(false);

  const updateBatchMessageStatusMutation = useMutation<
    unknown,
    unknown,
    Message[]
  >(() => markAllMessagesSeenForOneThread(messageThreadId));

  const { data: messages, ...queryMetadata } = useQuery<Message[]>(
    getAllMessagesQueryKey(messageThreadId),
    () => {
      if (messageType === MessageType.asrChat) {
        return getASRChatMessages(messageThreadId, isLiveChat);
      }
      if (messageType === MessageType.alertChat) {
        return getAlertChatMessages(messageThreadId);
      }
      return getMessages(messageThreadId);
    },
    {
      enabled: !!messageThreadId,
      onSuccess: messages => {
        if (!haveMarkedSeen && currentUser) {
          setHaveMarkedSeen(true);
          updateBatchMessageStatusMutation.mutate(messages);
        }
      },
      ...REFETCH_CONFIG,
    },
  );
  useNewMessageObserver(messageThreadId, messageType, refetchMembersLocation);
  useMessageStatusUpdateObserver(messageThreadId);

  return {
    messages: messages || [],
    ...queryMetadata,
  };
};

export default useMessages;
