import { useEffect } from 'react';

import {
  DeletedComment,
  joinComment,
  leaveComment,
  onCommentDeleted,
  onCommentUpdated,
  onNewComment,
  unsubscribeCommentDeleted,
  unsubscribeCommentUpdated,
  unsubscribeNewComment,
} from 'src/apis/socket.io/comment.socket.io';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/View.model';
import useComments from './useComments';

export const useNewAndExistingComment = (
  entityId: number,
  entityType: EntityType,
) => {
  const {
    data,
    refetch,
    addMultipleComments,
    addComment,
    updateComment,
    removeComment,
  } = useComments(entityId, entityType);

  const handleNewComment = (newComment: Comment) => {
    if (newComment.EntityId === entityId) {
      addComment(newComment);
    }
  };

  const handleUpdatedComment = (updatedComment: Comment) => {
    if (updatedComment.EntityId === entityId) {
      updateComment(updatedComment);
    }
  };

  const handleDeletedComment = (deletedComment: DeletedComment) => {
    if (deletedComment.entityId === entityId) {
      removeComment(deletedComment.commentId);
    }
  };

  useEffect(() => {
    onNewComment(handleNewComment);
    onCommentUpdated(handleUpdatedComment);
    onCommentDeleted(handleDeletedComment);

    return () => {
      unsubscribeNewComment(handleNewComment);
      unsubscribeCommentUpdated(handleUpdatedComment);
      unsubscribeCommentDeleted(handleDeletedComment);
    };
  }, []);

  useEffect(() => {
    if (!entityId) return;

    joinComment(entityId);

    return () => {
      leaveComment(entityId);
    };
  }, [entityId]);

  return { data, refetch, addMultipleComments };
};
