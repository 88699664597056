export const JapaneseTranslationValues = {
  login: ' ログイン',
  email: 'メール',
  enterPassword: ' パスワード',
  dontHaveAccount: ' アカウントをお持ちではありません',
  createOne: ' 一つ作る',
  register: ' 登録',
  firstName: '名',
  firstNamePlaceholder: ' ジョン',
  lastName: '姓',
  lastNamePlaceholder: ' ドウ',
  emailPlaceholder: ' john.doe@example.com',
  confirmPassword: ' パスワード(確認)',
  createAccount: ' アカウント作成',
  alreadyHaveAccount: 'アカウントをお持ちの場合',
  home: ' ホーム',
  emergencySOS: ' 緊急SOS',
  addMoreInfo: '情報を追加',
  photo: ' 写真',
  video: '動画',
  voice: '音声',
  text: ' テキスト',
  takeAPicture: ' 写真を撮影',
  previewFile: '写真を確認',
  recordVideo: '動画を撮影',
  videoPreview: '動画を確認',
  typeSomething: ' ここに文を入力',
  recordAudio: '音声を録音',
  previewAudio: '音声を確認',
  send: ' 送信',
  addMoreInfoToAlert: ' アラートに情報を追加',
  enterMessage: 'メッセージを入力',
  emergencyAlert: ' 緊急アラート',
  emergencyAlertRaised: ' 緊急アラートが発生',
  moreDetails: ' 詳細',
  alerts: ' アラート',
  active: ' アクティブ',
  resolved: ' 解決済み',
  alertDetails: ' アラートの詳細',
  location: '場所',
  image: '画像',
  audio: '音声',
  markAsResolved: ' 解決済みとしてマーク',
  alertIsResolved: ' アラートが解決されました',
  searchUsers: ' ユーザー検索',
  startSearchingUsers: ' ユーザー検索を開始',
  noUsersFound: ' ユーザーが見つかりません',
  myProfile: ' マイプロファイル',
  enableHfo: ' HFOを有効化',
  language: ' 言語',
  english: ' 英語',
  japanese: ' 日本語',
  logout: ' ログアウト',
  logoutFailed: ' ログアウトに失敗しました',
  pleaseTryAgain: ' 後でやり直してください',
  switchLanguage: ' 言語を切り替える',
  languageSwitchSuccess: ' 言語切り替えに成功しました',
  areYouSureLogout: ' 本当にログアウトしますか？',
  cancel: ' キャンセル',
  confirm: ' 確認',
  profile: ' プロフィール',
  phoneNumber: ' 電話番号',
  gender: '性別',
  female: ' 女性',
  male: ' 男性',
  addressLineOne: ' 住所1',
  addressLineTwo: ' 住所2',
  city: '市',
  state: '都道府県',
  postalCode: '郵便番号',
  country: ' 国',
  save: ' 保存',
  reset: ' リセット',
  profileUpdateSuccess: ' プロフィールが正常に更新されました',
  avatarUpdateSuccess: ' アバターが正常に更新されました',
  updateAvatar: ' アバターを更新',
  removeAvatar: ' アバターを削除',
  success: ' 成功',
  avatarRemovedSuccessfully: ' アバターが正常に削除されました',
  messages: ' メッセージ',
  personal: ' 個人',
  groups: ' グループ',
  newPersonalChat: ' 新しい個人チャット',
  searchForAUser: ' ユーザーを検索',
  viewProfile: ' プロフィールを表示',
  beginConversation: ' 会話を始める',
  search: '検索',
  startSpeaking: '音声入力開始',
  edit: ' 編集',
  en: '英語',
  ja: '日本語',
  connecting: ' 接続中',
  callRejected: ' 通話が拒否されました',
  callMissed: ' 通話が不在でした',
  userUnreachable: ' ユーザーに到達できません',
  incomingCall: ' 着信',
  newGroupChat: ' 新しいグループチャット',
  name: '名前',
  description: '説明',
  next: ' 次',
  previous: ' 前',
  createGroup: ' グループを作成',
  createdGroup: ' 作成されたグループ',
  addSelectedUser: ' 選択したユーザーを追加',
  viewMembers: ' メンバーを表示',
  editGroupInfo: ' グループ情報を編集',
  leaveGroup: ' グループから離脱',
  members: 'メンバー',
  admin: ' 管理者',
  makeAdmin: ' 管理者にする',
  removeFromGroup: ' グループから削除',
  editGroup: ' グループを編集',
  groupUpdated: ' グループが更新されました',
  groupUpdatedSuccessfully: ' グループが正常に更新されました',
  leaveGroupWarning: ' 本当にこのグループから離脱しますか？',
  emptyGroupMessage: ' このグループにメンバーはいません',
  groupCallWarning: ' メンバーがいないためグループ通話できません',
  calls: ' コール',
  selectUserToCall: ' コールするユーザーを選択',
  member: ' メンバー',
  joined: ' 参加',
  ended: ' 終了',
  duration: ' 持続時間',
  feeds: 'フィード',
  noCoverImageFeed: ' このフィードにはカバー画像がありません',
  noPostToShow: '投稿はありません',
  feedCreator: 'フィードの作成者',
  feedMembers: 'フィードメンバー',
  addFeedMembers: 'フィードメンバーを追加',
  managePermission: '権限を管理',
  manageFeedMemberPermission: 'フィードメンバーの権限を管理',
  memberPermission: 'メンバーの権限',
  canPost: '投稿可能',
  updatePermission: '権限を更新',
  removeFromFeed: 'フィードから削除',
  removeMember: 'メンバーを削除',
  userRemoveFromFeedWarning: '本当に{ユーザー名}をフィードから削除しますか？',
  review: 'レビュー',
  reviewPost: '投稿をレビュー',
  postApproval: '投稿承認',
  approvePost: '投稿を承認',
  rejectPost: '投稿を拒否',
  reviewRemarkOptional: 'レビューコメント（任意）',
  addRemark: '投稿についてのコメントを追加',
  submitPostReview: '投稿レビューを送信',
  postReviewUpdateSuccess: '投稿レビューが正常に更新されました',
  awaitingApproval: '承認待ち',
  approved: '承認',
  rejected: '拒否',
  writeSomethingHere: 'ここに書いてください',
  posts: '投稿',
  yourPosts: 'あなたの投稿',
  like: 'いいね',
  comment: 'コメント',
  addYourComment: 'コメントを追加',
  showMoreComments: 'さらに多くのコメントを表示',
  showLessComments: 'コメントを非表示',
  viewCount: 'ビュー',
  loadNewer: '新しいフィードを読み込む',
  loadingMore: 'さらに読み込む',
  editPost: '投稿を編集',
  deletePost: '投稿を削除',
  createPost: '投稿を作成',
  whatToPost: '何について投稿しますか？',
  document: 'ドキュメント',
  post: '投稿',
  images: '画像',
  documents: 'ドキュメント',
  videos: '動画',
  posting: '投稿中',
  newPostAdded: '新しい投稿が追加されました',
  updating: '更新中',
  postUpdated: '投稿が更新されました',
  postUpdateSuccess: '投稿が正常に更新されました',
  areYouSureMessage: '(確認)この操作は取り消せません',
  postDelete: '投稿が削除されました',
  pageCreator: 'ページの作成者',
  pageMembers: 'ページメンバー',
  previewChanges: '変更をプレビュー',
  publishChanges: '変更を公開',
  continueEditing: '編集を続ける',
  pageContentUpdated: 'ページコンテンツが更新されました',
  pageContentUpdatedSuccessfully: 'ページコンテンツが正常に更新されました',
  reviewRequest: 'レビューリクエスト',
  yourReviewSubmitted: 'レビューが送信されました',
  waitForAdminReview: '管理者がコンテンツを確認するのをお待ちください',
  addPageMembers: 'ページメンバーを追加',
  addSelectedUsers: '選択したユーザーを追加',
  removeFromPage: 'ページから削除',
  removeFromPageWarning: '本当に{ユーザー名}をページから削除しますか？',
  pageContent: 'ページのコンテンツ',
  pageRequest: 'ページリクエスト',
  discard: '破棄',
  pageContentDiscarded: 'ページコンテンツは破棄されました',
  reject: '拒否',
  accepted: '承認',
  approveRequest: 'リクエストを承認',
  rejectRemark: '備考を拒否',
  reviewRemark: '備考をレビュー',
  letReviewerKnowRemark: 'ページレビュワーに備考を通知',
  submitPageReview: 'ページレビューを送信',
  reviewRemarksSendSuccess: 'レビューが正常に送信されました',
  remarkSentToUser: 'レビューがユーザーに送信されました',
  pageContentApproved: 'ページのコンテンツが承認されました',
  insertLink: 'リンクを挿入',
  enterLinkUrl: 'リンクURLを入力',
  insert: '挿入',
  uploadMediaFromDevice: 'デバイスからメディアをアップロード',
  insertMediaUrl: 'URLを使用してメディアを挿入',
  insertMedia: 'メディアを挿入',
  requests: 'リクエスト',
  commandSampleOne: 'Kunalさんにメッセージを送信しました、お元気ですか？',
  commandSampleTwo: 'Johnさんに電話をかける',
  commandSampleThree: 'ＳＯＳを発信',
  adminLoginSuccess: '管理者ログイン成功',
  adminLogoutSuccess: '管理者ログアウト成功',
  loginFailed: 'ログイン失敗',
  addFeed: 'フィードを追加',
  numberOfFeeds: 'フィードの数',
  feedName: 'フィード名',
  createdBy: '作成者',
  public: '公開',
  moderated: 'モデレートされた',
  feedCreatedAt: 'フィード作成場所',
  actions: 'アクション',
  rowsPerPage: 'ページの行数',
  pageCount: 'ページ1/全1ページ',
  page: 'ページ',
  of: '全',
  createFeed: 'フィードを作成',
  addCoverImage: 'カバー画像を追加',
  coverImage: 'カバー画像',
  feedUrl: 'フィードURL',
  openToUsers: 'ユーザーに公開',
  allowPostByAnyRegisteredUser: '登録ユーザーによる投稿を許可',
  feedCreated: 'フィードが作成されました',
  feedAddedSuccessful: 'フィードが正常に追加されました',
  error: 'エラー',
  somethingWentWrong: '問題が発生しました。もう一度お試しください',
  pleaseTryAfterSometime: 'しばらくしてからもう一度お試しください',
  feedEdited: 'フィードが編集されました',
  feedEditedSuccessfully: 'フィードが正常に編集されました',
  deleteFeed: 'フィードを削除',
  areYouSureWarningMessage: '本当に削除しますか？ この操作は取り消せません',
  delete: '削除',
  yes: 'はい',
  no: 'いいえ',
  addPost: '投稿を追加',
  addMember: 'メンバーを追加',
  noPostFound: '投稿が見つかりません',
  filters: 'フィルター',
  searchByUser: 'ユーザーで検索',
  typeUserName: 'ユーザー名を入力',
  selectStartDate: '開始日を選択',
  selectEndDate: '終了日を選択',
  apply: '適用',
  totalNumberPost: '投稿の総数',
  totalNumberPostView: '投稿の総閲覧数',
  update: '更新',
  moderatePosts: '投稿をモデレート',
  archive: 'アーカイブ',
  moderatedMembers: 'モデレートされたメンバー',
  add: '追加',
  attachments: '添付ファイル',
  sections: 'セクション',
  dashboardSections: 'ダッシュボード',
  title: 'タイトル',
  type: 'タイプ',
  position: '位置',
  addSection: 'セクションを追加',
  addNewDashboardSection: '新しいダッシュボードセクションを追加',
  editDashboardSection: 'ダッシュボードセクションを編集',
  iconLink: 'アイコンリンク',
  textLink: 'テキストリンク',
  imageLink: '画像リンク',
  imageSlider: '画像スライダー',
  provideTitleForSection: 'セクションにタイトルを入力',
  positionMustBeGreater: '位置は1以上を入力',
  linkText: 'リンクテキスト',
  linkUrl: 'リンクURL',
  creator: '作成者',
  addLink: 'リンクを追加',
  addDashboardSectionLink: 'ダッシュボードセクションリンクを追加',
  startAt: '開始日',
  endsAt: '終了日',
  external: '外部',
  editDashboardSectionLink: 'ダッシュボードセクションリンクを編集',
  deleteDashboardSectionLink: 'ダッシュボードセクションリンクを削除',
  users: 'ユーザー',
  numberOfUsers: 'ユーザー数',
  avatar: 'アバター',
  userRegisteredOn: 'ユーザー登録日',
  addUser: 'ユーザーを追加',
  password: 'パスワード',
  deleteUser: 'ユーザーを削除',
  pages: 'ページ',
  addPage: 'ページを追加',
  pageTitle: 'ページタイトル',
  pageUrl: 'ページURL',
  createdOn: '作成日',
  addNewPage: '新しいページを追加',
  pageCreatedSuccessfully: 'ページが正常に作成されました',
  pageInfo: 'ページ情報',
  editPageMember: 'ページメンバーを編集する',
  userUpdatedSuccessfully: 'ユーザーが正常に更新されました',
  userUpdateFailed: 'ユーザーの更新に失敗しました',
  moderateUpdates: '更新をモデレート',
  moderateMembers: 'メンバーをモデレート',
  typeYourPageContentHere: 'ここにページのコンテンツを入力',
  canUpdate: '更新可能',
  canModerateUpdates: '更新をモデレート可能',
  canModerateMembers: 'メンバーをモデレート可能',
  alertGroups: 'アラートグループ',
  isAnonymous: '匿名',
  addAlertGroup: 'アラートグループを追加',
  nameOfTheGroup: 'グループ名',
  anonymous: '匿名',
  oneAlertGroupAlreadyExist: 'すでにアラートグループが存在しています',
  editTheAlertGroup: 'アラートグループを編集',
  groupMembers: 'グループメンバー',
  addGroupMember: 'グループメンバーを追加',
  deleteGroup: 'グループを削除',
  exportActivityData: 'アクティビティデータをエクスポート',
  downloadData: 'データをダウンロード',
  from: '開始日',
  to: '終了日',
  dataDownloadSuccess: 'データが正常にダウンロードされました',
  noDataFound: '指定された日付範囲内にデータが見つかりませんでした',
  emptyContent: '空のコンテンツは投稿できません',
  postMustHaveContent: '投稿にはテキスト、ファイル、またはその両方が必要です',
  commentEditedSuccessfully: 'コメントが正常に編集されました',
  commentAddedSuccessfully: 'コメントが正常に追加されました',
  fileTooLarge: 'ファイルが大きすぎます',
  fileSizeLimit: 'ファイルサイズは{}未満である必要があります',
  lacsUnitCreated: 'Lacsユニットが作成されました',
  lacsUnitCreatedSuccessfully: 'Lacsユニットが正常に作成されました',
  lacsGroupCreated: 'Lacsグループが作成されました',
  lacsGroupCreatedSuccessfully: 'Lacsグループが正常に作成されました',
  lacsGroupEdited: 'Lacsグループが編集されました',
  lacsGroupEditedSuccessfully: 'Lacsグループが正常に編集されました',
  dashboardSectionLink: 'ダッシュボードセクションリンク',
  oneAnonymousAlertGroupAlreadyExist:
    'すでに匿名アラートグループが存在しています',
  userAlreadyPartOfAlertGroup: 'ユーザーはすでにアラートグループの一部です',
  failedToLoginIn: 'ログインに失敗しました',
  unknownErrorOccurTryAgain:
    '不明なエラーが発生しました。後でもう一度お試しください。',
  inValidCredentials:
    'アカウントが無効です。ログインアカウントを確認してください。',
  youHaveSuccessfullyCreatedAccount: 'アカウントが正常に作成されました',
  pleaseLoginToContinue: '続行にはログインが必要です',
  failedToRegister: '登録に失敗しました',
  userWithSameNameAlreadyExist:
    'メールアドレスがすでに存在します。このメールアドレスでログインするか、別のメールアドレスをお試し下さい。',
  audioRecording: '音声録音',
  pageNotFound: 'ページが見つかりません',
  goToHome: 'ホームに戻る',
  sosAlertSuccess: 'SOSアラートが正常に発生しました',
  failToUpdateLang: '言語の更新に失敗しました',
  serverError: 'サーバーエラー',
  pleaseContactAdmin: '管理者に連絡してください',
  unknownErrorOccur: '不明なエラーが発生しました',
  raiseAnAlert: 'アラートを発生しました',
  draftPageMember: '下書きページ',
  by: 'によって',
  noPagesToShow: '表示するページはありません',
  noPagesYet: '表示するページはありません',
  unknownUser: '不明なユーザー',
  accept: '了承',
  block: 'ブロック',
  unblock: 'ブロック解除',
  youAreBlocked:
    'ブロックされました。この会話のメッセージ送信はできなくなりました',
  youHaveBlocked:
    'チャットをブロックしました。メッセージ送信するにはブロックを解除してください',
  filesTooLargeNotAttached: 'ファイルが大きすぎるため添付できません',
  newMessages: '新しいメッセージ',
  today: '今日',
  yesterday: '昨日',
  errorUpdatingGroup: 'グループ更新エラー',
  fetchingGroup: 'グループ取得中',
  previewFiles: 'ファイルプレビュー',
  selectMembers: 'メンバー選択',
  messageTooLong: 'メッセージが長すぎます',
  messageCannotBeMoreThan: 'メッセージは10000文字を超えることはできません',
  failedToSendMessage: 'メッセージの送信に失敗しました',
  isTyping: '入力中です',
  areTyping: '入力中です',
  typing: '入力中',
  more: 'もっと',
  areYouSureYouWantToRemove: '{user name}を削除してもよろしいですか？',
  fromTheFeed: 'フィードから',
  fromTheGroup: 'グループから',
  thisMember: 'このメンバーを',
  you: 'あなた',
  permissionsUpdated: '権限が更新されました',
  feed: 'フィード',
  failedToCreatePost: '投稿の作成に失敗しました',
  comments: 'コメント',
  loading: '読み込み中',
  noCommentsToShow: '表示するコメントはありません',
  deleteComment: 'コメント削除',
  updateComment: 'コメント更新',
  editComment: 'コメント編集',
  likes: 'いいね',
  nothingMoreToLoad: 'データがありません',
  backgroundUpdating: 'バックグラウンドで更新中',
  letThePostAuthorKnow: '投稿者にコメント送信',
  postReview: '投稿確認',
  reviewedBy: '確認者',
  reviewRemarks: '確認コメント',
  reviewedAt: '確認時刻',
  approve: '承認',
  usersWhoLikePost: 'いいねしたユーザー',
  noFeedsYet: 'フィードはありません',
  loadingMap: 'マップを読み込んでいます',
  pleaseEnterEmail: 'メールアドレスを入力してください',
  pleaseEnterValidEmail: '有効なメールアドレスを入力してください',
  pleaseEnterPassword: 'パスワードを入力してください',
  addMembersToGroup: 'メンバーをグループに追加',
  failedMakingAdmin: '管理者作成に失敗しました',
  failedRemovingAdmin: '管理者削除に失敗しました',
  removeAdmin: '管理者権限を削除',
  enterNameForGroup: 'グループの名前を入力してください',
  groupNameLongerThan: 'グループ名は{number}文字より長くなければなりません',
  groupNameNotLongerThan: 'グループ名は{number}文字を超えてはいけません',
  noGroupChatsYet: 'グループチャットがありません',
  createGroupChat: '以下の+ボタンを押して作成してください',
  noPersonalChatsYet: '個人チャットがありません。',
  createPersonalChat: '以下の+ボタンを押して作成してください',
  errorFetchingGroupInfo:
    'グループ情報の取得中にエラーが発生しました。後でもう一度お試しください。',
  groupDescription: 'グループの説明',
  groupInfo: 'グループ情報',
  loadingChats: 'チャットを読み込み中',
  sentAFile: 'ファイルを送信しました',
  removeVideo: '動画を削除しました',
  errorApprovingPageContent: 'ページ投稿の承認にエラーが発生しました',
  errorDiscardingPageContent: 'ページ投稿の破棄にエラーが発生しました',
  errorSubmittingYourReview: 'コメントの投稿にエラーが発生しました',
  pageContentRejected: 'ページ投稿は拒否されました',
  reviewForYourRequest: 'リクエストの確認',
  publishingChanges: '更新内容を公開',
  connect: '接続',
  address: '住所',
  noDataToShow: '表示するデータはありません',
  addImage: '画像追加',
  updateImage: '画像更新',
  applyFilter: 'フィルタ適用',
  canModeratePosts: '更新権限あり',
  editMember: 'メンバー編集',
  errorRemovingMedia: 'メディア削除にエラーが発生しました',
  somethingWentWrongMedia: 'メディア削除に問題が発生しました',
  errorUpdatingPost: '投稿の更新にエラーが発生しました',
  somethingWentWrongUpdatingPost: '投稿の更新に問題が発生しました',
  feedMemberDeleted: 'フィードメンバーが削除されました',
  editFeed: 'フィード編集',
  createPage: 'ページ作成',
  creatingPage: 'ページ作成中',
  errorUpdatingPageContent: 'ページコンテンツ更新にエラーが発生しました',
  errorWhileDiscardingPageContent: 'ページコンテンツ破棄にエラーが発生しました',
  currentPageContent: '現在のページコンテンツ',
  draftPageContent: '下書きページコンテンツ',
  errorSendingReviewRemark: 'レビューコメント送信にエラーが発生しました',
  pageUpdatedSuccessfully: 'ページが正常に更新されました',
  savingChanges: '変更保存中',
  pageMemberDeleted: 'ページメンバーが削除されました',
  draftRequest: '下書きリクエスト',
  userWithSamePhoneNumberAlreadyExists:
    '同じ電話番号のユーザーが既に存在します',
  userCreatedSuccessfully: 'ユーザーが正常に作成されました',
  userAlreadyExists: 'ユーザーはすでに存在します',
  user: 'ユーザー',
  callerReceiverCantBeSame: '発信元と受信者は同じにすることはできません',
  youMustBeCallReceiverToAcknowledge:
    '返信するためには、セッションの受信者でなければなりません',
  youMustBeCallReceiverToReject:
    '拒否するためには、セッションの受信者でなければなりません',
  youMustBeCallReceiverToAccept:
    '承認するためには、セッションの受信者でなければなりません',
  youMustBeCallReceiverToLeave:
    '終了するためには、セッションの受信者でなければなりません',
  callSessionNotFound: 'コールセッションがありません',
  errorWhileCreatingCall: 'コール発信中にエラーが発生しました',
  callNotFound: 'コール受信者IDが見つかりません',
  youDontHavePermissionToCreateDashboardSection:
    'ダッシュボードを作成する権限がありません',
  memberAlreadyExistInFeed: 'メンバーはすでにフィードに存在します',
  youAreNotAllowedToAddMember: 'メンバーを追加する権限がありません',
  youAreNotAllowedToPost: '投稿する権限がありません',
  feedAlreadyExist: 'フィードはすでに存在します',
  feedNotFound: 'フィードが見つかりません',
  userIsNotGroupAdmin: 'ユーザーはグループ管理者ではありません',
  lacsGroupNotCreated: 'Lacsグループが作成されていません',
  youAreNotAuthorizedToUpdateLacsGroup:
    'Lacsグループを更新する権限がありません',
  youAreNotAllowedToAddAdmin: '管理者追加する権限がありません',
  youCannotRemoveYourselfAsAdmin:
    '自分自身を管理者から削除することはできません',
  youDonotHavePermissionToCreatePage: 'ページ作成する権限がありません',
  youDonotHavePermissionToUpdatePage: 'ページ更新する権限がありません',
  unableToIdCommand: 'コマンドが見つかりません。もう一度お試しください',
  unableToIdUser: 'ユーザーが見つかりません。もう一度お試しください',
  unableToExecuteCommand:
    'コマンドを実行できません。後でもう一度お試しください',
  invalidPostalAreaCode: '無効な郵便番号',
  invalidUserId: '無効なユーザーID',
  forgotPassword: 'パスワードを忘れた方',
  searchingForUsers: 'ユーザー検索中',
  noRequestsPending: '保留中のリクエストなし',
  pageDraft: 'ページ下書き',
  contentStatus: 'コンテンツの状態',
  editContent: 'コンテンツを編集',
  noContentFound: 'コンテンツがありません',
  draft: '下書き',
  youHaveNoCallsYet: '通話なし',
  createACall: '通話ボタンをタップして通話を開始します',
  youDontHaveGroupCalls: 'グループ通話なし',
  incomingGroupCall: 'グループ通話の着信',
  reviewBy: 'レビューア',
  tryToSay: '発言する',
  youAreNotAuthorizedToUpdateLacsUnit: 'LACSユニットを更新する権限がありません',
  lacsGroups: 'Lacsグループ',
  createLacsGroup: 'Lacsグループ作成',
  organizationName: '組織名',
  subdomain: 'サブドメイン',
  departmentName: '部門名',
  contactPersonName: '連絡先名',
  contactPersonDesignation: '連絡先肩書',
  shareDataAmongUnits: 'ユニット間でデータを共有',
  editLacsGroup: 'Lacsグループ編集',
  groupAdminList: 'グループ管理者リスト',
  existingAdmins: '既存の管理者',
  createLacsUnit: 'Lacsユニット作成',
  unitId: 'ユニットID',
  unitName: 'ユニット名',
  IsCloudUnit: 'クラウドユニット',
  editLacsUnit: 'LACSユニットを編集',
  cloudUnit: 'クラウドユニット',
  NoOfAdmins: '管理者数',
  addUnit: 'ユニットを追加',
  adminAddedSuccessfully: '管理者が正常に追加されました',
  addGroupAdmins: 'グループ管理者を追加',
  created: '作成ずみ',
  numberOfUnits: 'ユニット数',
  totalGroupAdmins: 'グループ管理者数',
  viewGroupAdmins: 'グループ管理者を表示',
  ViewAdmins: '管理者を表示',
  addGroup: 'グループを追加',
  youAreNotAllowedToRemoveAdmin: '管理者を削除する権限がありません',
  youAreAlreadyAnAdminOfThisUnit: 'すでにこのユニットの管理者です',
  lacsUnitWithThisIdAlreadyExist: 'このIDのLACSユニットはすでに存在します',
  youAreNotAuthorizedToCreateLacsUnit: 'LACSユニットを作成する権限がありません',
  unableToCreateLacsUnitThisTime: 'この時点でLACSユニットを作成できません',
  lacsUnitNotFound: 'LACSユニットが見つかりません',

  // Validation messages
  pleaseEnterComment: 'コメントを入力してください',
  pleaseEnterFirstName: '名前を入力してください',
  pleaseEnterConfirmPassword: '再度パスワードを入力してください',
  passwordMismatch: '入力したパスワードが一致しません',
  pleaseEnterLastName: '名字を入力してください',
  pleaseEnterValidPhoneNumber: '有効な電話番号を入力してください',
  pleaseSelectGender: '性別を選択してください',
  pleaseEnterValidUrl: '有効なURLを入力してください',
  pleaseEnterUrl: 'URLを入力してください',
  groupNameMinLength: 'グループ名の入力文字数は3文字以上です',
  groupNameMaxLength: 'グループ名の入力文字数は30文字以内です',
  pleaseEnterGroupName: 'グループ名を入力してください',
  pleaseEnterValidPostalCode: '有効な郵便番号を入力してください',
  pleaseEnterGroupDescription: 'グループの説明文を入力してください',
  pleaseEnterFeedName: 'フィード名を入力してください',
  feedIsPublicOrNot: 'フィードが公開されているかどうかを指定してください',
  openToUserOrNot: 'ユーザーに公開されているかどうかを指定してください',
  anyUserCanPostOrNot:
    'ユーザーに投稿を許可するか非許可にするか設定してください',
  feedIsModeratedOrNot:
    'フィードを特権ユーザーに公開するか非公開にするか設定してください',
  pleaseEnterOrganizationName: '組織名を入力してください',
  pleaseEnterDepartmentName: '部門名を入力してください',
  pleaseEnterPhoneNumber: '電話番号を入力してください',
  pleaseEnterCity: '市区町村を入力してください',
  pleaseEnterState: '都道府県を入力してください',
  pleaseEnterCountry: '国名を入力してください',
  pleaseEnterLacsUnitId: 'ユニットIDを入力してください',
  pleaseEnterUnitName: 'ユニット名を入力してください',
  pleaseEnterDescription: '説明文を入力してください',
  pleaseEnterLocationName: '場所名を入力してください',
  cloudUnitOrNot: 'クラウドユニットか非クラウドユニットか設定してください',
  pleaseSelectSectionType: 'タイプを選択してください',
  pleaseEnterValidPosition: 'セクションの有効な位置を指定してください',
  pleaseEnterPosition: 'セクションの位置を指定してください',
  pleaseEnterTitle: 'セクションのタイトルを指定してください',
  titleCannotBeLongerThan: 'タイトルは60文字以内です',
  linkTitleCannotBeLongerThan: 'リンクタイトルは60文字以内です',
  pleaseEnterLinkTitle: 'リンクタイトルを入力してください',
  pleaseEnterValidLinkUrl: '有効なリンクURLを入力してください',
  pleaseSpecifyIfLinkIsExternal: '外部リンクか非外部リンクかを指定してください',
  pleaseEnterPageTitle: 'ページタイトルを入力してください',
  pageTitleMustBeAtLeast: 'ページタイトルは4文字以上必要です',
  pleaseSelectPrivacySetting: 'プライバシー設定を入力してください',
  pleaseSelectModerationSetting: 'モデレーション設定を入力してください',
  pleaseSelectUserAccessSetting: 'ユーザーアクセス設定を入力してください',
  feedNameShouldBeAtLeast4Characters: 'フィード名は4文字以上必要です',
  feedNameShouldBeAtMost30Characters: 'フィード名は30文字以内です',
  applicationHeading: process.env.REACT_APP_JAPANESE_APPLICATION_HEADING,
  sentAnImage: 'イメージ送信',
  sentAVideo: '画像送信',
  sentAnAudio: '音声送信',
  stop: '停止',
  yourCurrentLocation: '現在の位置',
  asr: '音声認識',
  startASRChat: '音声認識開始',
  messageSendSuccessfully: '送信成功',
  youAreNotAMemberOfThisGroup: 'グループメンバーではありません',
  incomingRadioCommunication: '無線通信要求',
  additionalDetails: '詳細',
  addOneMoreMemberToStartAGroupCall:
    'グループ通話を開始するにはもう1人のメンバーを追加してください',

  memberAlreadyExistInDefaultGroup:
    'メンバーはすでにデフォルトグループに存在します',
  defaultAlertGroupNotFound: 'デフォルトアラートグループが見つかりません',
  nothingToShow: '表示するものがありません',
  quickUpdate: 'クイック更新',
  statusReport: 'ステータスレポート',
  statusUpdate: 'ステータス更新',
  links: 'リンク',
  close: '閉じる',
  loginToReport: 'レポートするにはログインしてください',
  alertStatusReport: 'アラートステータスレポート',
  disasterCategory: '災害カテゴリ',
  message: 'メッセージ',
  chooseReportCategory: 'レポートカテゴリを選択',
  done: '完了',
  reply: '返信',
  report: 'レポート',
  messageTime: 'メッセージ時間',
  userNotInAlertGroup: 'ユーザーはアラートグループにいません',
  alertNotFound: 'アラートが見つかりません',
  mapSectionAndMore: 'マップセクションとその他',
  ownLocation: '自分の位置',
  alertsLocation: 'アラートの位置',
  usersLocation: 'ユーザーの位置',
};
