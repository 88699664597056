import { socket } from 'src/config/socketIoInstance';
import { Post } from 'src/models/Post.model';
import { ValueCallback } from 'src/types/common.type';
import { kFeedPostEvents } from './events/feed-post.events';

export type DeletedFeedPost = {
  postId: number;
  feedId: number;
};

export const onNewFeedPost = (callback: ValueCallback<Post>) =>
  socket.on(kFeedPostEvents.postCreated, callback);

export const unsubscribeNewFeedPost = (callback: ValueCallback<Post>) => {
  socket.off(kFeedPostEvents.postCreated, callback);
};

export const onFeedPostUpdated = (callback: ValueCallback<Post>) =>
  socket.on(kFeedPostEvents.postUpdated, callback);

export const unsubscribeFeedPostUpdated = (callback: ValueCallback<Post>) => {
  socket.off(kFeedPostEvents.postUpdated, callback);
};

export const onFeedPostDeleted = (callback: ValueCallback<DeletedFeedPost>) =>
  socket.on(kFeedPostEvents.postDeleted, callback);

export const onFeedPostReview = (callback: ValueCallback<Post>) =>
  socket.on(kFeedPostEvents.postReview, callback);

export const unsubscribeFeedPostReview = (callback: ValueCallback<Post>) => {
  socket.off(kFeedPostEvents.postReview, callback);
};

export const unsubscribeFeedPostDeleted = (
  callback: ValueCallback<DeletedFeedPost>,
) => {
  socket.off(kFeedPostEvents.postDeleted, callback);
};

export const joinFeedPost = (feedId: number) => {
  socket.emit(kFeedPostEvents.joinFeedPost, { feedId });
};

export const leaveFeedPost = (feedId: number) => {
  socket.emit(kFeedPostEvents.leaveFeedPost, { feedId });
};
