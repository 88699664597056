import { Box, Center, Flex, StackProps, Text } from '@chakra-ui/layout';
import { Button, Divider, HStack, Icon, useDisclosure } from '@chakra-ui/react';
import Linkify from 'linkify-react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import { addLikesofPost } from 'src/apis/likes.api';
import { TranslationKeys } from 'src/constants/translation-keys';
import { FeedPostLikeDto } from 'src/dto/feed-post-likes.dto';
import { useUserDetails } from 'src/hoc/UserDetailsProvider';
import { useUserLocation } from 'src/hoc/UserLocationContext';
import { useToast } from 'src/hooks/use-toast';
import { useTranslate } from 'src/hooks/useTranslate';
import { Comment } from 'src/models/Comment.model';
import { EntityType } from 'src/models/FeedPostView.model';
import { Post } from 'src/models/Post.model';
import { COMMENT_CARD_ROUTE } from 'src/routes/routeList';
import { Colors } from 'src/utils/chakra-theme/theme-blocks/color';

import { CommentForm } from './CommentForm';
import {
  COMMENT_CARD_URL,
  MAXIMUM_NUMBER_OF_COMMENT_TO_SHOW,
} from './constants/post-form.constant';
import { DeleteCommentModal } from './DeleteCommentModal';
import { EditCommentModal } from './EditCommentModal';
import { FeedPostLikes } from './FeedPostLikes';
import { useAddViewIntersection } from './hooks/useAddViewIntersection';
import useLikes from './hooks/useLikes';
import { useNewAndExistingComment } from './hooks/useNewAndExistingPostComment';
import PostActionsMenu from './PostActionsMenu';
import PostCardHeader from './PostCardHeader';
import PostCardMedia from './PostCardMediaSlider';
import { PostCommentCard } from './PostCommentCard';

var linkProps = {
  style: { color: Colors.colors.hyperlink.color },
};

interface NewPostCardProps extends StackProps {
  post: Post;
  showPostApprovalStatus?: boolean;
}

const PostCard: React.FC<NewPostCardProps> = ({
  post,
  showPostApprovalStatus,
  ...props
}) => {
  const { currentUser } = useUserDetails();
  const { showToast } = useToast();
  const [hasLiked, setHasLiked] = useState(false);
  const { translate } = useTranslate();
  const [showAllComments, setShowAllComments] = useState<boolean>(false);
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [commentToEdit, setCommentToEdit] = useState<Comment>();
  const [commentIdToDelete, setCommentIdToDelete] = useState<number>();

  const location = useLocation();
  const { locationStream } = useUserLocation();
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: openDeleteDialog,
    onClose: closeDeleteDialog,
  } = useDisclosure();

  const { horizontalLine } = useAddViewIntersection(post);
  const { data: LikesData, refetch: refetchLikes } = useLikes(
    post.id,
    EntityType.post,
  );
  const {
    data: commentsData,
    refetch: refetchComments,
    addMultipleComments,
  } = useNewAndExistingComment(post.id, EntityType.post);

  const addPostLikeMutation = useMutation((postLike: FeedPostLikeDto) =>
    addLikesofPost(postLike),
  );

  const toggleCommentEditDialog = (comment?: Comment) => {
    setCommentToEdit(comment);
  };

  const likePost = () => {
    if (!currentUser) {
      showToast({ status: 'warning', title: 'Please login to like the post' });
      return;
    }
    setHasLiked(prevHasLiked => !prevHasLiked);
    addPostLikeMutation.mutate(
      {
        ...locationStream,
        EntityId: post.id,
        entityType: EntityType.post,
      },
      {
        onSuccess: data => {
          refetchLikes();
        },
      },
    );
  };

  const commentOnPost = () => {
    if (!currentUser) {
      showToast({
        status: 'warning',
        title: 'Please login to comment the post',
      });
      return;
    }
    setShowCommentBox(prevState => !prevState);
  };

  const textContent = post.textContent;
  const CommentCardFeedIdURL = COMMENT_CARD_ROUTE.replace(
    ':id',
    String(post.FeedId),
  );
  const CommentCardFeedIdWithPostIdURL = CommentCardFeedIdURL.replace(
    ':postId',
    String(post.id),
  );

  useEffect(() => {
    if (location.pathname.endsWith(COMMENT_CARD_URL)) {
      setShowAllComments(true);
    }
  }, []);

  return (
    <>
      <Flex
        flexDir='column'
        borderRadius='8'
        borderColor='gray.400'
        borderStyle='solid'
        borderWidth='1px'
        my='4'
        pt='2'
        bg='white'
        {...props}
      >
        <HStack minW='full' alignItems='start' justify='space-between'>
          <PostCardHeader
            post={post}
            showPostApprovalStatus={showPostApprovalStatus}
            my='2'
          />
          <PostActionsMenu post={post} pt='2' />
        </HStack>
        <Box minW='full' h='1px' bg='gray.300' />
        <Text
          mt='3'
          mb='2'
          as='pre'
          fontFamily='inherit'
          minWidth='0px'
          whiteSpace='pre-wrap'
          px='4'
        >
          <Linkify options={{ attributes: linkProps }}>{textContent}</Linkify>
        </Text>
        <PostCardMedia post={post} />
        <Flex justifyContent='space-between' px='3'>
          {LikesData && (
            <FeedPostLikes
              LikesData={LikesData}
              post={post}
              setHasLiked={setHasLiked}
            />
          )}
          <Flex>
            <Flex
              bg='red'
              ref={horizontalLine}
              display='flex'
              justifyContent='flex-end'
            ></Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex w='full' py='1'>
          <Button variant='unstyled' w='full' onClick={likePost}>
            <Flex justifyContent='center'>
              <Icon
                as={hasLiked ? AiFillLike : AiOutlineLike}
                boxSize='6'
                color='blue.500'
              />
              <Text pl='3'>{translate(TranslationKeys.like)}</Text>
            </Flex>
          </Button>
          <Center height='40px'>
            <Divider orientation='vertical' bgColor='gray.400' />
          </Center>
          <Button variant='unstyled' w='full' onClick={commentOnPost}>
            <Flex justifyContent='center'>
              <Icon as={BiComment} boxSize='6' />
              <Text pl='3'>{translate(TranslationKeys.comment)}</Text>
            </Flex>
          </Button>
        </Flex>
        {showCommentBox && (
          <CommentForm
            addMultipleComments={addMultipleComments}
            postId={post.id}
            refetchComments={refetchComments}
            {...props}
          />
        )}
        {<Divider />}
        {commentsData &&
          _.map(commentsData, (comment, index) => {
            return index < MAXIMUM_NUMBER_OF_COMMENT_TO_SHOW ? (
              <PostCommentCard
                key={comment.id}
                comment={comment}
                openReviewDialog={toggleCommentEditDialog}
                openDeleteDialog={openDeleteDialog}
                setCommentIdToDelete={setCommentIdToDelete}
              />
            ) : showAllComments ? (
              <PostCommentCard
                key={comment.id}
                comment={comment}
                openReviewDialog={toggleCommentEditDialog}
                openDeleteDialog={openDeleteDialog}
                setCommentIdToDelete={setCommentIdToDelete}
              />
            ) : null;
          })}
        {(commentsData && commentsData.length && (
          <Flex px='3' mt='2' pb='1'>
            <Link to={CommentCardFeedIdWithPostIdURL}>
              <Button
                onClick={() => setShowAllComments(prevState => !prevState)}
                variant='link'
                color='blue.500'
              >
                <Text color='blue.500' fontSize='sm'>
                  {showAllComments
                    ? translate(TranslationKeys.showLessComments)
                    : translate(TranslationKeys.showMoreComments)}
                </Text>
              </Button>
            </Link>
          </Flex>
        )) ||
          null}
      </Flex>
      {commentToEdit && (
        <EditCommentModal
          isOpen={!!commentToEdit}
          onClose={toggleCommentEditDialog}
          post={post}
          comment={commentToEdit}
        />
      )}

      {commentIdToDelete && (
        <DeleteCommentModal
          isOpen={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          commentId={commentIdToDelete}
          post={post}
        />
      )}
    </>
  );
};

export default PostCard;
